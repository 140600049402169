export const SET_CURRENT_FONT_SIZE = 'SET_CURRENT_FONT_SIZE'
export const SET_USER_DEVICE_TYPE = 'SET_USER_DEVICE_TYPE'
export const SET_VIDEO_BANNER_TYPE = 'SET_VIDEO_BANNER_TYPE'

// UI
export const SET_VIDEO_MODAL_OPEN = 'SET_VIDEO_MODAL_OPEN'
export const SET_VIDEO_MODAL_CLOSED = 'SET_VIDEO_MODAL_CLOSED'
export const SET_IMAGE_MODAL_OPEN = 'SET_IMAGE_MODAL_OPEN'
export const SET_IMAGE_MODAL_CLOSED = 'SET_IMAGE_MODAL_CLOSED'
export const SET_MODAL_CLOSED = 'SET_MODAL_CLOSED'
export const SET_CONTENT_MODAL_OPEN = 'SET_CONTENT_MODAL_OPEN'

// DATA
export const SET_DOCTORS_FILTER = 'SET_DOCTORS_FILTER'
export const UPDATE_DOCTORS_FILTER = 'UPDATE_DOCTORS_FILTER'
export const SAVE_DATA = 'SAVE_DATA'

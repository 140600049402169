export const faqData = {
  banner: {
    bgImage: 'faq__banner@desktop.jpg',
    bgImageTablet: 'faq__banner@desktop.jpg',
    bgImageMobile: 'faq__banner@desktop.jpg',
    title: 'Частые вопросы и ответы',
  },
  faqList: [
    {
      key: `Для чего нужна профессиональная чистка зубов у&nbsp;стоматолога?`,
      value: `
          <p>Очень часто причиной воспаленных, кровоточащих десен является плохая гигиена полости рта. Поэтому на&nbsp;начальной стадии пародонтита (гингивита) профессиональная гигиеническая чистка является обязательным мероприятием.</p>
          <p>Есть труднодоступные места (межзубные промежутки и&nbsp;пародонтальные карманы), в&nbsp;которых самостоятельно в&nbsp;домашних условиях удалить бактериальный налет невозможно.</p>
        `
    },
    {
      key: `Что такое &laquo;Аiг flow&raquo;?`,
      value: `
          <p>Аir flow позволяет:</p>
          <p>&mdash;&nbsp;легко удалить плотный пигментированный налет и&nbsp;мягкие зубные отложения, даже в&nbsp;тех сложных ситуациях, когда другие способы очистки бывают малоэффективны</p>
          <p>&mdash;&nbsp;очистить и&nbsp;тем самым подготовить зубы к&nbsp;лечению, что, в&nbsp;свою очередь, увеличивает срок службы реставраций, способствует повышению качества работы, упрощает подбор цвета пломбировочного материала</p>
          <p>&mdash;&nbsp;обеспечить профилактику кариеса и&nbsp;заболеваний пародонта</p>
        `
    },
    {
      key: `К&nbsp;чему приводит наличие зубных камней?`,
      value: `
          <p>По&nbsp;локализации различают два вида зубного камня: наддесневой и&nbsp;поддесневой. Сначала образуется наддесневой зубной камень, который за&nbsp;короткое время разрушает зубодесневую борозду и&nbsp;формирует поддесневой зубной камень.</p>
          <p>Поддесневой зубной камень интенсивно растет внутрь по&nbsp;корню зуба, разрушая связочный аппарат периодонта (опорно-удерживающие связки зуба в&nbsp;челюстной кости). Впоследствии этого образуется зубодесневой карман&nbsp;&mdash; промежуток между десной и&nbsp;зубом, а&nbsp;в&nbsp;последующем&nbsp;&mdash; костный карман.</p>
          <p>Все это впоследствии приводит к&nbsp;расшатыванию и&nbsp;потере совсем здоровых зубов.</p>
          <p>Во&nbsp;избежание всего вышесказанного настоятельно рекомендуем проводить процедуру профессиональной чистки зубов два раза в&nbsp;год.</p>
        `
    },
  ],
}
export const PRICES_DATA = {
  banner: {
    bgImage: 'stardend24-prices__bg.jpg',
    bgImageMobile: 'stardend24-prices__bg.jpg',
    bgImageTablet: 'stardend24-prices__bg.jpg',
    title: 'Стоимость лечения',
    descriptor: 'Звоните, поможем со всем разобраться'
  },
  list: [
    {
      category: 'Консультация',
      sublist: [
        {
          subcategory: `Консультация`,
          services: [
            {
              label: 'Прием (осмотр, консультация) врача-стоматолога-терапевта первичный',
              price: `500`
            },
            {
              label: 'Прием (осмотр, консультация) врача-стоматолога-терапевта повторный',
              price: `500`
            },
            {
              label: 'Прием (осмотр, консультация) врача-стоматолога-ортопеда первичный',
              price: `500`
            },
            {
              label: 'Прием (осмотр, консультация) врача-стоматолога-ортопеда повторный',
              price: `500`
            },
            {
              label: 'Прием (осмотр, консультация) врача-стоматолога-хирурга первичный',
              price: `1000`
            },
            {
              label: 'Прием (осмотр, консультация) врача-стоматолога-хирурга повторный',
              price: `1000`
            },
            {
              label: 'Справка о санации полости рта',
              price: `500`
            },
          ]
        },
      ]
    },
    {
      category: `Исследования и диагностика`,
      sublist: [
        {
          services: [
            {
              label: 'Осмотр полости рта с помощью дополнительных инструментов',
              price: `500`
            },
            {
              label: 'Осмотр полости рта с помощью дополнительных инструментов с изоляцией Optragate',
              price: `1000`
            },
            {
              label: 'Антропометрические исследования (медицинское фотографирование)',
              price: `3000`
            },
          ]
        }
      ]
    },
    {
      category: `Анестезия, инъекции`,
      sublist: [
        {
          services: [
            {
              label: 'Проводниковая анестезия',
              price: `500`
            },
            {
              label: 'Аппликационная анестезия',
              price: `500`
            },
            {
              label: 'Инфильтрационная анестезия',
              price: `500`
            },
          ]
        }
      ]
    },
    {
      category: `Профилактика`,
      sublist: [
        {
          services: [
            {
              label: 'Аппликация  лекарственного препарата на слизистую оболочку полости рта (1 зуб)',
              price: `500`
            },
            {
              label: 'Местное применение реминерализующих препаратов в области  зуба',
              price: `500`
            },
            {
              label: 'Обучение гигиене полости рта, подбор средств гигиены',
              price: `500`
            },
            {
              label: 'Запечатывание фиссуры зуба герметиком',
              price: `3000`
            },
            {
              label: 'Профессиональная гигиена полости рта и зубов (ультразвук+AirFlow+фторлак) со всех зубов',
              price: `10000`
            },
            {
              label: 'Профессиональная гигиена полости рта и зубов (ультразвук) со всех зубов',
              price: `7000`
            },
            {
              label: 'Профессиональная гигиена полости рта и зубов (AirFlow) со всех зубов',
              price: `7000`
            },
            {
              label: 'Ультразвуковое удаление наддесневых и поддесневых зубных отложений в области 1 зуба',
              price: `500`
            },
          ]
        }
      ]
    },
    {
      category: `Терапевтические услуги`,
      sublist: [
        {
          subcategory: `Восстановление зуба пломбой`,
          services: [
            {
              label: 'Сошлифовывание твердых тканей зуба при  лечении кариеса и его осложнений',
              price: `500`
            },
            {
              label: 'Изоляция системой Коффердам/Раббердам',
              price: `1000`
            },
            {
              label: 'Изоляция Optragate',
              price: `500`
            },
            {
              label: 'Постановка лечебной и изолирующей прокладок светового отверждения',
              price: `2000`
            },
            {
              label: 'Восстановление зуба пломбой с использованием материалов из фотополимеров',
              price: `5000`
            },
            {
              label: 'Восстановление зуба виниром, полукоронкой из фотополимерного материала прямым  методом',
              price: `8000`
            },
            {
              label: 'Полирование пломбы',
              price: `500`
            },
            {
              label: 'Восстановление зуба пломбой с использованием силиконового ключа',
              price: `2000`
            },
            {
              label: 'Фиксация скайса',
              price: `5000`
            },
          ]
        },
        {
          subcategory: `Лечение осложнений кариеса (эндодонтическое лечение корневых каналов)`,
          services: [
            {
              label: `Трепанация зуба, искусственной коронки`,
              price: `2000`
            },
            {
              label: `Наложение девитализирующей пасты`,
              price: `500`
            },
            {
              label: `Экстирпация пульпы`,
              price: `1000`
            },
            {
              label: `Ультразвуковое расширение корневого канала зуба`,
              price: `2000`
            },
            {
              label: `Распломбировка 1 корневого канала ранее леченного пастой`,
              price: `2000`
            },
            {
              label: `Распломбировка 1 корневого канала ранее леченного гуттаперчевыми штифтами`,
              price: `5000`
            },
            {
              label: `Распломбировка 1 корневого канала ранее леченного фосфат- цементом/ резорцинформальдегидным  методом`,
              price: `5000`
            },
            {
              label: `Инструментальная и медикаментозная обработка хорошо проходимого  1 корневого канала`,
              price: `2000`
            },
            {
              label: `Инструментальная и медикаментозная обработка плохо проходимого 1 корневого канала`,
              price: `4000`
            },
            {
              label: `Пломбирование 1 корневого канала зуба гуттаперчевыми штифтами`,
              price: `3000`
            },
            {
              label: `Временное пломбирование лекарственным  препаратом 1 корневого канала`,
              price: `1500`
            },
            {
              label: `Закрытие перфорации стенки 1 корневого канала зуба`,
              price: `4000`
            },
            {
              label: `Фиксация внутриканального штифта/вкладки`,
              price: `2000`
            },
            {
              label: `Удаление внутриканального штифта/вкладки`,
              price: `5000`
            },
          ]
        },
        {
          subcategory: `Временные пломбы`,
          services: [
            {
              label: `Наложение временной пломбы из самоотверждаемого материала`,
              price: `500`
            },
            {
              label: `Наложение временной пломбы из фотоотверждаемого материала`,
              price: `1000`
            },
            {
              label: `Снятие временной пломбы`,
              price: `500`
            },
          ]
        },
        {
          subcategory: `Шинирование зубов`,
          services: [
            {
              label: `Временное шинирование при заболеваниях пародонта (шинирование двух зубов)`,
              price: `2000`
            },
          ]
        },
        {
          subcategory: `Отбеливание зуба`,
          services: [
            {
              label: `Профессиональное отбеливание зубов внутрикоронковое для невитальных измененных в цвете зубов (1 зуб)`,
              price: `5000`
            },
          ]
        },
        {
          subcategory: `Пародонтология терапевтическая`,
          services: [
            {
              label: `Введение лекарственных препаратов в пародонтальный карман`,
              price: `2000`
            },
            {
              label: `Избирательное пришлифовывание твердых тканей зубов`,
              price: `500`
            },
          ]
        },
      ]
    },
    {
      category: 'Ортопедические услуги',
      sublist: [
        {
          subcategory: `Оттиски`,
          services: [
            {
              label: 'Снятие оттиска с одной челюсти',
              price: `5000`
            },
            {
              label: 'Снятие оттиска с применением аппарата Пентамикс',
              price: `10000`
            },
            {
              label: 'Снятие оттиска с одной челюсти  для изготовления силиконового ключа',
              price: `3000`
            },
          ]
        },
        {
          subcategory: `Модели, прикус`,
          services: [
            {
              label: `Определение вида смыкания зубных рядов с помощью лицевой дуги`,
              price: `15000`
            },
            {
              label: `Диагностика окклюзионных взаимоотношений с применением  лицевой дуги и артикулятора`,
              price: `25000`
            },
            {
              label: `Исследование на диагностических моделях челюстей`,
              price: `5000`
            },
            {
              label: `Исследование на диагностических моделях челюстей с восковой моделировкой (WАx-Up) будущей ортопедической конструкции с целью планирования препарирования, эстетики и функции (1 единица)`,
              price: `1000`
            },
            {
              label: `Определение прикуса при помощи примерки в полости рта результата воскового моделирования (Moke-Up) из временного композитного материала, планирования эстетики и функции (1 единица)`,
              price: `1000`
            },
          ]
        },
        {
          subcategory: `Несъемное протезирование`,
          services: [
            {
              label: `Сошлифовывание твердых тканей зуба для последующего восстановления вкладкой, накладкой, полукоронкой, коронкой, виниром`,
              price: `2000`
            },
            {
              label: `Восстановление зуба коронкой временной прямым  методом`,
              price: `3000`
            },
            {
              label: `Восстановление зуба коронкой временной лабораторным методом`,
              price: `5000`
            },
            {
              label: `Восстановление зуба коронкой постоянной металлокерамической стандартной`,
              price: `15000`
            },
            {
              label: `Восстановление зуба коронкой постоянной металлокерамической с плечевой массой`,
              price: `20000`
            },
            {
              label: `Восстановление зуба коронкой постоянной безметалловой из диоксида циркония`,
              price: `30000`
            },
            {
              label: `Восстановление зуба коронкой постоянной безметалловой из материала E.маx`,
              price: `30000`
            },
            {
              label: `Восстановление зуба коронкой цельнолитой (кобальтохромовый сплав)`,
              price: `10000`
            },
            {
              label: `Цельнолитая культевая вкладка (кобальтохромовый сплав)`,
              price: `7000`
            },
            {
              label: `Вкладка-накладка «керамическая пломба» из материала E.max`,
              price: `30000`
            },
          ]
        },
        {
          subcategory: `Съемные протезы`,
          services: [
            {
              label: `Акриловый протез частичный с армированием`,
              price: `40000`
            },
            {
              label: `Акриловый протез полный с армированием`,
              price: `40000`
            },
            {
              label: `Акриловый протез полный с литым  базисом`,
              price: `45000`
            },
            {
              label: `Акриловый протез с телескопической фиксацией (без стоимости телескопических коронок)`,
              price: `200000`
            },
            {
              label: `Изготовление телескопической коронки (2 колпачка)`,
              price: `40000`
            },
            {
              label: `Акриловый микропротез при одностороннем включенном дефекте`,
              price: `15000`
            },
            {
              label: `Acry Free протез частичный`,
              price: `45000`
            },
            {
              label: `Acry Free протез полный`,
              price: `50000`
            },
            {
              label: `Acry Free микропротез при одностороннем включенном дефекте`,
              price: `20000`
            },
            {
              label: `Бюгельный металлический протез с кламмерной фиксацией`,
              price: `60000`
            },
            {
              label: `Бюгельный металлический протез с замковой фиксацией`,
              price: `70000`
            },
            {
              label: `Шинирующий бюгельный протез - постоянное шинирование цельнолитыми съемными конструкциями при заболеваниях пародонта`,
              price: `70000`
            },
            {
              label: `Замок для бюгельного протеза (1 единица)`,
              price: `15000`
            },
            {
              label: `Замена силиконовой матрицы в замке бюгельного протеза`,
              price: `5000`
            },
            {
              label: `Quadrotti протез «гибкий бюгельный протез»`,
              price: `55000`
            },
            {
              label: `Quadrotti микропротез при одностороннем  включенном  деффекте`,
              price: `20000`
            },
          ]
        },
        {
          subcategory: `Протезирование с опорой на имплантаты`,
          services: [
            {
              label: `Временная коронка на имплантат`,
              price: `10000`
            },
            {
              label: `Металлокерамическая коронка на имплантат с винтовой/цементной фиксацией (без учета стоимости индивидуального абатмента/ титанового основания)`,
              price: `20000`
            },
            {
              label: `Абатмент индивидуальный титановый`,
              price: `15000`
            },
            {
              label: `Титановое основание`,
              price: `15000`
            },
            {
              label: `Безметалловая коронка на имплантат из диоксида циркония цементной фиксации (без учета стоимости индивидуального абатмента)`,
              price: `30000`
            },
            {
              label: `Абатмент индивидуальный из диоксида циркония`,
              price: `20000`
            },
            {
              label: `Безметалловая коронка на имплантат из диоксида циркония винтовой фиксации на титановом основании`,
              price: `45000`
            },
            {
              label: `Акриловый протез съемный с фиксацией на шариковых абатментах`,
              price: `250000`,
              min: true
            },
            {
              label: `Абатмент шариковый из титана`,
              price: `20000`,
            },
            {
              label: `Абатмент мультиюнит`,
              price: `20000`,
            },
            {
              label: `Акриловый протез съемный с фиксацией на титановой балке`,
              price: `250000`,
              min: true
            },
            {
              label: `Титановая балка`,
              price: `200000`,
              min: true
            },
            {
              label: `Акриловый протез с винтовой фиксацией на имплантатах`,
              price: `400000`,
              min: true
            },
            {
              label: `Временный условносъемный протез по системе все на 4х или на 6 имплантатах`,
              price: `80000`,
              min: true
            },
            {
              label: `Постоянный условносъемный металлокерамический протез по системе все на 6 имплантатах`,
              price: `500000`,
              min: true
            },
          ]
        },
        {
          subcategory: `Прочие ортопедические услуги`,
          services: [
            {
              label: `Повторная фиксация на постоянный цемент несъемных ортопедических конструкций (1 единица)`,
              price: `1000`
            },
            {
              label: `Повторная фиксация на постоянный цемент несъемных ортопедических конструкций с опорой на имплантаты (1 единица)`,
              price: `2000`
            },
            {
              label: `Повторная фиксация на временный цемент несъемных ортопедических конструкций (1 единица)`,
              price: `500`
            },
            {
              label: `Снятие несъемной ортопедической конструкции (1 единица)`,
              price: `1000`
            },
            {
              label: `Снятие несъемной ортопедической конструкции с имплантата (1 единица)`,
              price: `5000`
            },
            {
              label: `Снятие несъемной ортопедической конструкции с имплантата с извлечением  из шахты  поврежденного винта`,
              price: `10000`
            },
            {
              label: `Избирательное пришлифовывание твердых тканей зубов (1 единица)`,
              price: `1000`
            },
            {
              label: `Перебазировка съемного протеза  лабораторным методом (жесткая прокладка)`,
              price: `10000`
            },
            {
              label: `Перебазировка съемного протеза лабораторным методом (мягкая прокладка)`,
              price: `10000`
            },
            {
              label: `Приварка кламмера`,
              price: `5000`
            },
            {
              label: `Приварка зуба`,
              price: `5000`
            },
            {
              label: `Починка перелома базиса протеза самотвердеющей пластмассой`,
              price: `5000`
            },
            {
              label: `Починка перелома базиса протеза самотвердеющей пластмассой с армированием`,
              price: `20000`
            },
            {
              label: `Коррекция съемного протеза-пришлифовка`,
              price: `500`
            },
          ]
        },
      ]
    },
    {
      category: `Хирургические услуги`,
      sublist: [
        {
          services: [
            {
              label: `Наложение повязки при операциях в полости рта`,
              price: `500`
            },
            {
              label: `Удаление временного зуба`,
              price: `2000`
            },
            {
              label: `Удаление постоянного зуба`,
              price: `от 3 000 до 7 000`
            },
            {
              label: `Удаление зуба сложное с разъединением корней`,
              price: `от 7 000 до 15 000`
            },
            {
              label: `Операция удаления ретинированного, дистопированного или сверхкомплектного зуба`,
              price: `от 15 000 до 25 000`
            },
            {
              label: `Резекция верхушки корня зуба`,
              price: `20000`
            },
            {
              label: `Вскрытие подслизистого или поднадкостничного очага воспаления в полости рта`,
              price: `2000`
            },
            {
              label: `Дренирование одонтогенного абсцесса`,
              price: `3000`
            },
            {
              label: `Отсроченный кюретаж лунки удаленного зуба`,
              price: `2000`
            },
            {
              label: `Гингивэктомия с применением диатермокоагулятора`,
              price: `3000`
            },
            {
              label: `Лечение перикоронита (промывание, рассечение и/или иссечение капюшона)`,
              price: `5000`
            },
            {
              label: `Пластика перфорации верхнечелюстной пазухи`,
              price: `20000`
            },
            {
              label: `Остеотомия челюсти с удалением имплантата`,
              price: `от 15 000 до 30 000`
            },
            {
              label: `Лоскутная операция в полости рта при заборе трансплантата с бугра верхней челюсти`,
              price: `15000`
            },
            {
              label: `Лоскутная операция в полости рта при заборе трансплантата с нёба`,
              price: `20000`
            },
            {
              label: `Лоскутная операция в полости рта с пластикой рецессии десны в области 1 зуба`,
              price: `25000`
            },
            {
              label: `Синус-лифтинг закрытый`,
              price: `от 20 000 до 50 000`
            },
            {
              label: `Синус-лифтинг открытый`,
              price: `от 30 000 до 80 000`
            },
            {
              label: `Коррекция объема и формы альвеолярного отростка - наращивание кости`,
              price: `от 20 000 до 90 000`
            },
            {
              label: `Гингивопластика - наращивание десны`,
              price: `от 10 000 до 30 000`
            },
            {
              label: `Наложение шва на слизистую оболочку полости рта`,
              price: `1000`
            },
            {
              label: `Снятие шва со слизистой оболочки полости рта`,
              price: `500`
            },
          ]
        },
      ]
    },
    {
      category: `Имплантация (хирургическая часть)`,
      sublist: [
        {
          services: [
            {
              label: `Исследование на диагностических моделях челюстей с изготовлением хирургического шаблона для позиционирования имплантатов`,
              price: `20000`
            },
            {
              label: `Внутрикостная дентальная имплантация системы «STRAUMANN» (Швейцария)`,
              price: `55000`
            },
            {
              label: `Внутрикостная дентальная имплантация системы «XIVE» (Германия)`,
              price: `55000`
            },
            {
              label: `Внутрикостная дентальная имплантация системы «DENTIUM» (Южная Корея)`,
              price: `35000`
            },
            {
              label: `Внутрикостная дентальная имплантация системы «SNUCONE» (Южная Корея)`,
              price: `35000`
            },
            {
              label: `Установка формирователя десны`,
              price: `10000`
            },
          ]
        },
      ]
    },
    {
      category: `CEREC технология`,
      sublist: [
        {
          services: [
            {
              label: `Снятие оттиска с одной челюсти с использованием внутриротового оптического цифрового сканера CEREC технология`,
              price: `5000`
            },
            {
              label: `Вкладка-накладка «керамическая пломба» CEREC технология`,
              price: `30000`
            },
            {
              label: `Винир  CEREC технология`,
              price: `30000`
            },
            {
              label: `Восстановление зуба коронкой безметалловой CEREC технология`,
              price: `30000`
            },
            {
              label: `Безметалловая коронка на имплантат  винтовой фиксации на титановом основании CEREC технология`,
              price: `45000`
            },
            {
              label: `Абатмент индивидуальный на титановом основании CEREC технология`,
              price: `20000`
            },
          ]
        },
      ]
    },
  ]
}
